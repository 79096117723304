import { useEffect, useState } from 'react';
import { FAQPageJsonLd } from 'next-seo';
import RemoveMd from 'remove-markdown';

import Link from 'components/common/Link';
import ParagraphHeader from 'components/ParagraphHeader';
import Button from 'components/common/Button';

import { FaqAndSeoProps } from 'interfaces/cms/content';
import FaqItem from './FaqItem';

function FaqAndSeo({ bg, header, faqs, button }: FaqAndSeoProps) {
  const [openFaqIds, setOpenFaq] = useState<any[]>([]);

  useEffect(() => {
    const autoExpandFaqs: any[] = [];
    const query = new URLSearchParams(document.location.search);
    const faqIdParam = Number(query.get('faq_id'));

    faqs?.forEach(({ autoExpand, id }) => {
      if (autoExpand) {
        autoExpandFaqs.push(id);
      }
    });
    setOpenFaq(autoExpandFaqs);

    if (faqIdParam) {
      autoExpandFaqs.push(faqIdParam);
    }
  }, [faqs]);

  const onOpenFaq = (id: any) => {
    if (openFaqIds.some((openId) => openId === id)) {
      setOpenFaq((prev) => prev.filter((openId) => openId !== id));
    } else {
      setOpenFaq([...openFaqIds, id]);
    }
  };

  if (!faqs) return null;

  const faqData = faqs.map((faq) => ({
    questionName: RemoveMd(faq.title).replace(/['"]+/g, ''),
    acceptedAnswerText: faq.customEditor,
  }));

  return (
    <>
      <FAQPageJsonLd mainEntity={faqData} />

      <div
        className="px-[0.9375rem] py-[1.875rem]"
        style={{ backgroundColor: bg }}
      >
        {header && (
          <>
            <ParagraphHeader {...header} />
            <div className="mb-[1.875rem]" />
          </>
        )}

        <div className="w-full md:w-[45.625rem] mx-auto">
          {faqs.map((faq) => (
            <FaqItem key={faq.id} {...{ ...faq, openFaqIds, onOpenFaq }} />
          ))}
        </div>

        {(button?.buttonUrl || button?.buttonPageSlug?.slug) && (
          <div className="text-center mt-[1.875rem]">
            <Link
              href={button.buttonPageSlug?.slug || button.buttonUrl}
              rel={button.buttonLinkRel}
              isExternalLink={!!button.buttonUrl}
            >
              <Button
                {...button}
                buttonType="outline"
                btnStyle="min-w-[70%] md:min-w-[21.875rem]"
              />
            </Link>
          </div>
        )}
      </div>
    </>
  );
}

export default FaqAndSeo;
